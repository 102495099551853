/** @format */

/* ./src/tailwind.css */

/*@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Helvetica:wght@300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins';
    font-weight: normal;
}

.w-250 {
    width: 250px;
}

.list-item {
    display: list-item;
    list-style-position: inside;
}

.disabled {
    pointer-events: none;
    cursor: not-allowed;
}
/* for custom icon in slidenavbar */
.iconsetup:hover .iconcolor {
    fill: #5850ff !important;
}

.stickey {
    transform: translateY(-100%);
}
.text_box {
    width: 88%;
}
.choose_box {
    width: 73%;
}
.terms {
    height: 50vh;
}
.instructions {
    height: calc(100vh - 120px);
}
.schedule_h_content {
    height: calc(100vh - 143px);
}
.instructions-inner {
    height: calc(100vh - 420px);
}
.h-dash-order {
    height: calc(100% - 380px);
}
.h-report-order {
    height: calc(100% - 580px);
}
.h-report1-order {
    height: calc(100% - 700px);
}
.h-category {
    height: calc(100vh - 327px);
}
.h-area {
    height: calc(100vh - 240px);
}
.h-table {
    height: calc(100vh - 285px);
}
.h-category-search {
    width: calc(100vw - 1031px);
}
.h-sub-category {
    height: calc(100vh - 249px);
}
.h_timing {
    height: calc(100% - 315px);
}
.os_box {
    height: 112px;
}
.text_active:active {
    background-color: #2563eb;
    color: white;
    border-color: #2563eb;
}
.text_active:focus {
    background-color: #2563eb;
    color: white;
    border-color: #2563eb;
}
::-webkit-scrollbar {
    width: 5px;
    height: 5px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
    background-color: #2563eb; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: none; /* creates padding around scroll thumb */
}

.custom-scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.text-overflow {
    inline-size: 100px;
    overflow: hidden;
}
.h-main-content {
    height: calc(100vh - 150px);
}
.h-tabview-content {
    height: calc(100vh - 170px);
}
.h-submain-content {
    height: calc(100vh - 550px);
    /* height: calc(100vh - 330px); */
}
.word-break {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
}

#react-select-3-input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

#react-select-5-input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}
.css-6j8wv5-Input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}
.h-overflow-scroll {
    height: calc(100vh - 190px);
}
.privacy {
    list-style: disc;
    margin: 0px 20px;
}
ol,
ul {
    list-style: none;

    padding: 0px;
}
.category-image-card {
    padding: 6px;
    box-shadow: 0px 1px 2px 2px #eee;
    border-radius: 0.375rem;
}
@media screen and (min-width: 900px) {
    .category {
        display: grid;
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

.h-menu-schedule {
    height: calc(100vh - 377px);
}
.h-menu-schedule-one {
    height: calc(100vh - 470px);
}

@media (max-width: 640px) {
    .h_timing_one {
        height: calc(100% - 586px);
    }
}

.progressbar {
    display: flex;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: conic-gradient(red attr(data-progressOne), gray 0deg);
    font-size: 0;
    animation: 0.4s ease-out turn_in reverse;
}

.progressbar::after {
    content: attr(data-progress);
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 10px;
    border-radius: 50%;
    background: white;
    font-size: 1rem;
    text-align: center;
}

@keyframes turn_in {
    5% {
        background: conic-gradient(red calc(var(--progress) * 0.95), gray 0deg);
    }
    10% {
        background: conic-gradient(red calc(var(--progress) * 0.9), gray 0deg);
    }
    15% {
        background: conic-gradient(red calc(var(--progress) * 0.85), gray 0deg);
    }
    20% {
        background: conic-gradient(red calc(var(--progress) * 0.8), gray 0deg);
    }
    25% {
        background: conic-gradient(red calc(var(--progress) * 0.75), gray 0deg);
    }
    30% {
        background: conic-gradient(red calc(var(--progress) * 0.7), gray 0deg);
    }
    35% {
        background: conic-gradient(red calc(var(--progress) * 0.65), gray 0deg);
    }
    40% {
        background: conic-gradient(red calc(var(--progress) * 0.6), gray 0deg);
    }
    45% {
        background: conic-gradient(red calc(var(--progress) * 0.55), gray 0deg);
    }
    50% {
        background: conic-gradient(red calc(var(--progress) * 0.5), gray 0deg);
    }
    55% {
        background: conic-gradient(red calc(var(--progress) * 0.45), gray 0deg);
    }
    60% {
        background: conic-gradient(red calc(var(--progress) * 0.4), gray 0deg);
    }
    65% {
        background: conic-gradient(red calc(var(--progress) * 0.35), gray 0deg);
    }
    70% {
        background: conic-gradient(red calc(var(--progress) * 0.3), gray 0deg);
    }
    75% {
        background: conic-gradient(red calc(var(--progress) * 0.25), gray 0deg);
    }
    80% {
        background: conic-gradient(red calc(var(--progress) * 0.2), gray 0deg);
    }
    85% {
        background: conic-gradient(red calc(var(--progress) * 0.15), gray 0deg);
    }
    90% {
        background: conic-gradient(red calc(var(--progress) * 0.1), gray 0deg);
    }
    95% {
        background: conic-gradient(red calc(var(--progress) * 0.05), gray 0deg);
    }
    100% {
        background: conic-gradient(gray 0deg);
    }
}

@media (max-width: 767px) {
    .grid-cols-12 {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}

.truncate-md {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 14em; /* You can adjust the max width as needed for medium screens */
}

@media (min-width: 768px) {
    .truncate-md {
        max-width: none;
    }
}

@media (min-width: 375px) {
    .truncate-md {
        max-width: none;
    }
}

/* popup override export CSV field */

.custom-width .react-datepicker-wrapper {
    width: 100%;
}

/* To hide the number spinner in profile page */

.no-spinner input[type='number']::-webkit-outer-spin-button,
.no-spinner input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.ql-container {
    @apply max-h-screen overflow-y-auto;
}
.tooltip {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.group:hover .tooltip {
    opacity: 1;
}
